import React from 'react'

const PropOne = ({ projectStyle, portfolio, title }) => {
	return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className='thumbnail'>
					<img src={process.env.PUBLIC_URL + portfolio.image} alt='icon' />
				</div>
				<div className='content'>
					<h3 className='title' style={{ fontSize: '1.2rem' }}>
						{portfolio.title}
					</h3>
					<span className='subtitle' style={{ fontSize: '0.9rem' }}>
						{portfolio.role.map((cat, i) => (
							<span key={i}>{cat}</span>
						))}
					</span>
				</div>
			</div>
		</>
	)
}

export default PropOne
