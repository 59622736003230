import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ErrorPage = () => {

    return (
        <>
        <SEO/>
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="error-page onepage-screen-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <AnimationOnScroll animateIn="slideInUp" duration={1} delay={300} animateOnce={true}>
                                    <div className="content">
                                        <h1 className="title" style={{ fontSize: "var(--h3)" }}>Oops! Page Not Found</h1>
                                        <p>We can't find the page you're looking for. It might be gone or the address might be wrong.</p>
                                        <ul style={{ listStyle: "none" }}>
                                            <li>Double-Check the URL</li>
                                            <li>Go Back <Link to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
                                            <li>Explore <Link to={process.env.PUBLIC_URL + "/team"}>Our Team</Link></li>
                                        </ul>
                                        <p>We apologize for any inconvenience this may have caused.</p>
                                        <Link to={process.env.PUBLIC_URL + "/"} className="axil-btn btn-fill-primary">Go Back</Link>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-lg-6">
                                <AnimationOnScroll animateIn="zoomIn" duration={1} delay={300} animateOnce={true}>
                                    <div className="thumbnail">
                                        <img src={process.env.PUBLIC_URL + "/images/others/404.png"} alt="404" />
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="shape"/>
                        </li>
                        <li className="shape shape-2">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-27.png"} alt="shape"/>
                        </li>
                        <li className="shape shape-3">
                            <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="shape"/>
                        </li>
                    </ul>
                </div>
            </main>
        </>
    )
}

export default ErrorPage;
