import React from 'react'
import {
	FaFacebookF,
	FaLinkedinIn,
	FaTiktok,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './banner.scss'

const BannerOne = () => {
	return (
		<div className='banner banner-style-1'>
			<div className='container'>
				<div className='row align-items-end align-items-xl-start'>
					<div className='col-lg-6'>
						<div className='banner-content'>
							{/* <AnimationOnScroll
								animateIn="slideInUp"
								duration={1}
								animateOnce={true}
								delay={100}
							> */}
							{/* <span className="subtitle">WE MAKE IT HAPPEN</span> */}
							{/* </AnimationOnScroll> */}
							{/* <AnimationOnScroll
								animateIn="fadeInUp"
								animateOnce={true}
								delay={100}
							> */}
							<h1 className='title'>Zuse Technologies</h1>
							<span className='subtitle'></span>
							<p>
							Zuse Technologies stands as your premier partner for cutting-edge IT product development. With a steadfast commitment to innovation and excellence, we harness the power of leading-edge technologies and industry best practices to craft custom-made IT solutions. Our mission is to empower clients in achieving their business objectives with dependable, cost-efficient, and forward-thinking IT solutions.
							</p>

							<div className='author-info'>
								<div className='thumb'>
									<Link to={process.env.PUBLIC_URL + '/products/bitrix24-gold-partner'}>
										<img
											className='product_ad'
											src={
												process.env.PUBLIC_URL +
												'/images/zuse_products/bitrix-favicon-small.webp'
											}
											srcset={`
												${process.env.PUBLIC_URL}/images/zuse_products/bitrix-favicon-small.webp 1x,
												${process.env.PUBLIC_URL}/images/zuse_products/bitrix-favicon-large.webp 2x
											`}
											alt='Bitrix 24 Gold Partners'
										/>
									</Link>
									<Link to={process.env.PUBLIC_URL + '/products/magiya'}>
									<img
										className='product_ad'
										src={
											process.env.PUBLIC_URL +
											'/images/zuse_products/magiya-favicon-small.webp'
										}
										srcset={`
											${process.env.PUBLIC_URL}/images/zuse_products/magiya-favicon-small.webp 1x,
											${process.env.PUBLIC_URL}/images/zuse_products/magiya-favicon-large.webp 2x
										`}
										alt='Magiya'
									/>
									</Link>
									{/* <Link to={process.env.PUBLIC_URL + '/portfolio/lyceum-international-schools'}>
									<img
										className='product_ad'
										src={
											process.env.PUBLIC_URL +
											'/images/zuse_products/bestweb-lk-gold-winner-2023.webp'
										}
										srcset={`
											${process.env.PUBLIC_URL}/images/zuse_products/bestweb-lk-gold-winner-2023.webp 1x,
											${process.env.PUBLIC_URL}/images/zuse_products/bestweb-lk-gold-winner-2023-large.webp 2x
										`}
										alt='bestweb lk gold winner 2023'
									/>
									</Link> */}
								</div>
							</div>
							<Link
								to={process.env.PUBLIC_URL + '/about-us'}
								className='axil-btn btn-fill-primary btn-large'
								aria-label='Read More about our company and team'
							>
								Read More About Us
							</Link>
							{/* </AnimationOnScroll> */}
						</div>
					</div>
					<div className='col-lg-6'>
						<div className='banner-thumbnail'>
							<img
								src={process.env.PUBLIC_URL + '/images/zuse/zuse-home-hands.webp'}
								alt='zuse home hands'
							/>
						</div>
					</div>
					<div className='banner-social'>
						<div className='border-line' />
						<ul className='list-unstyled social-icon'>
							<li>
								<a
									target='_blank'
									href='https://web.facebook.com/zusetechnologies'
								>
									<FaFacebookF /> Facebook
								</a>
							</li>
							<li>
								<a
									target='_blank'
									href='https://www.linkedin.com/company/zusetechnologies/'
								>
									<FaLinkedinIn /> Linkedin
								</a>
							</li>
							<li>
								<a target='_blank' href='https://www.tiktok.com/@zusetech'>
									<FaTiktok /> Tiktok
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<ul className='list-unstyled shape-group-21'>
				<li className='shape shape-1'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-39.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-2'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-38.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-3'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-14.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-4'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-14.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-5'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-14.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-6'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-40.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-7'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-41.png'}
						alt="shape"
					/>
				</li>
			</ul>
		</div>
	)
}

export default BannerOne
