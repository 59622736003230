import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { slugify } from '../../utils';
import SplashData from '../../data/splash/SplashData.json';

const DemoData = SplashData[0];

var slideSettings = {
    infinite: true,
    speed: 500,
    autoplaySpeed: 1500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            variableWidth: false
          }
        }
    ]
}

const SplashBanner = () => {
    return (
        <div className="section splash-main-banner">
            <div className="demo-slider">
                <Slider {...slideSettings} className="slick-dot-nav">
                    {DemoData.slice(0, 5).map((data) => (
                        <div className="single-slide" key={data.id}>
                            <Link to={`${process.env.PUBLIC_URL}/${slugify(data.title)}`}>
                                <img src={`${process.env.PUBLIC_URL}${data.width_img}`} alt={data.title}/>
                            </Link>
                        </div>
                    ))}
                </Slider> 
            </div>

            {/* <ul className="list-unstyled shape-group-20">
                <li className="shape shape-1">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-36.png"} alt="shape"/>
                </li>
                <li className="shape shape-4">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="shape"/>
                </li>
                <li className="shape shape-5">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-37.png"} alt="shape"/>
                </li>
                <li className="shape shape-7">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"} alt="shape"/>
                </li>
            </ul> */}
        </div>
    )
}

export default SplashBanner;