import React from 'react';
import { Link } from 'react-router-dom';


const BlogGridTwo = ({data, colSize}) => {


    return (
        <>
            {data.map((blog) => (
                <div className={colSize} key={blog.route_url}>
                    <div className="product-grid product-grid-2">
                        <div className="post-thumbnail">
                            <Link to={process.env.PUBLIC_URL + `/products/${blog.route_url}`}><img src={`${process.env.PUBLIC_URL}/${blog.category_img}`} alt="thumbnail"/></Link>
                        </div>
                        <div className="author">
                            <div className="info">
                                <h6 className="author-name">{blog.author_name}</h6>
                                <ul className="blog-meta list-unstyled">
                                    <li>{blog.post_date}</li>
                                    <li>{blog.read_time}</li>
                                </ul>
                            </div>
                        </div>
                        <h2 className="title" style={{ fontSize: "var(--h5)" }}>
                            <Link to={process.env.PUBLIC_URL + `/products/${blog.route_url}`}>{blog.title}</Link>
                        </h2>
                        <p>{blog.excerpt}</p>
                        <Link className="axil-btn btn-borderd" to={process.env.PUBLIC_URL + `/products/${blog.route_url}`}>Read More</Link>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BlogGridTwo;