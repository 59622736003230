import React, { useEffect } from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';

const ColorSwitcher = () => {
    useEffect(() => {
        const isDarkMode = localStorage.getItem('darkMode') !== 'false';
        if (isDarkMode) {
            document.body.classList.add('active-dark-mode');
        } else {
            document.body.classList.remove('active-dark-mode');
        }
    }, []);

    const switchColor = () => {
        const isCurrentlyDark = document.body.classList.contains('active-dark-mode');
        document.body.classList.toggle('active-dark-mode');
        localStorage.setItem('darkMode', !isCurrentlyDark);
    };

    return (
        <div className="my_switcher d-none d-lg-block">
            <button onClick={switchColor} aria-label="Color Switcher">
                <span className="setColor dark"><FaLightbulb /></span>
                <span className="setColor light"><FaMoon /></span>
            </button>
        </div>
    );
};

export default ColorSwitcher;
