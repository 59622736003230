import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = () => {
  const location = useLocation();
  const siteName = "Zuse Technologies";
  const defaultTitle = "Zuse Technologies | We Make IT Happen";
  const defaultDescription =
    "Experience innovation with Zuse Technologies – your partner for effective IT solutions, utilizing the latest tech to achieve business goals.";
  const defaultKeywords =
    "Innovative IT Solutions, Cutting-edge Technology, IT Product Development, Reliable IT Services, Cost-effective Solutions, Technology Partner, Customized IT Solutions, Business-driven Technology, Advanced Technology Practices, IT Consultancy Services, Software Development Expertise, Strategic IT Planning, Future-proof Solutions, Client-centric Approach, Digital Transformation Services";
  const defaultCanonicalUrl = "https://zuse.lk";

  const metaTags = {
    "/": {
      description:
        "Experience innovation with Zuse Technologies – your partner for effective IT solutions, utilizing the latest tech to achieve business goals.",
      keywords:
        "IT solutions company, Innovative IT services, Digital transformation, Managed IT services, Customized IT services",
      canonicalUrl: "https://zuse.lk",
    },
    "/contact": {
      title: "Contact Us",
      description:
        "Free IT consultation & agile solutions. Start your project today with Zuse Technologies. Request a quote!",
      keywords:
        "Contact Zuse Technologies, IT services inquiry, IT support contact, Get in touch with Zuse Technologies, IT solutions phone number",
      canonicalUrl: "https://zuse.lk/contact/",
    },
    "/contact/": {
      title: "Contact Us",
      description:
        "Free IT consultation & agile solutions. Start your project today with Zuse Technologies. Request a quote!",
      keywords:
        "Contact Zuse Technologies, IT services inquiry, IT support contact, Get in touch with Zuse Technologies, IT solutions phone number",
      canonicalUrl: "https://zuse.lk/contact/",
    },
    "/about-us": {
      title: "About Us",
      description:
        "Learn more about Zuse Technologies, our mission, and our team of experts dedicated to delivering top-notch IT solutions.",
      keywords:
        "About Zuse Technologies, IT company history, Our mission and vision, IT expertise, IT solutions provider",
      canonicalUrl: "https://zuse.lk/about-us/",
    },
    "/about-us/": {
      title: "About Us",
      description:
        "Learn more about Zuse Technologies, our mission, and our team of experts dedicated to delivering top-notch IT solutions.",
      keywords:
        "About Zuse Technologies, IT company history, Our mission and vision, IT expertise, IT solutions provider",
      canonicalUrl: "https://zuse.lk/about-us/",
    },
    "/team": {
      title: "Our Team",
      description:
        "Meet Zuse's tech minds! Innovative, dedicated, & passionate about building your success.",
      keywords:
        "Zuse Technologies team, IT professionals, Expert team, Dedicated team, Technology experts",
      canonicalUrl: "https://zuse.lk/team/",
    },
    "/team/": {
      title: "Our Team",
      description:
        "Meet Zuse's tech minds! Innovative, dedicated, & passionate about building your success.",
      keywords:
        "Zuse Technologies team, IT professionals, Expert team, Dedicated team, Technology experts",
      canonicalUrl: "https://zuse.lk/team/",
    },
    "/privacy-policy": {
      title: "Privacy Policy",
      description:
        "Explore Zuse Technologies' Privacy Policy to understand how we collect, use, protect, and manage your personal information.",
      keywords:
        "Zuse Technologies privacy policy, Data protection policy, User privacy policy, Information security policy, Personal data protection",
      canonicalUrl: "https://zuse.lk/privacy-policy/",
    },
    "/privacy-policy/": {
      title: "Privacy Policy",
      description:
        "Explore Zuse Technologies' Privacy Policy to understand how we collect, use, protect, and manage your personal information.",
      keywords:
        "Zuse Technologies privacy policy, Data protection policy, User privacy policy, Information security policy, Personal data protection",
      canonicalUrl: "https://zuse.lk/privacy-policy/",
    },
    "/solutions/it-infrastructure": {
      title: "IT Infrastructure Solution Details",
      description:
        "Transform your business with our latest infastructural solutions.",
      keywords:
        "IT infrastructure solutions, Network infrastructure solutions, Cloud infrastructure services, Data center solutions, IT infrastructure optimization",
      canonicalUrl: "https://zuse.lk/solutions/it-infrastructure/",
    },
    "/solutions/it-infrastructure/": {
      title: "IT Infrastructure Solution Details",
      description:
        "Transform your business with our latest infastructural solutions.",
      keywords:
        "IT infrastructure solutions, Network infrastructure solutions, Cloud infrastructure services, Data center solutions, IT infrastructure optimization",
      canonicalUrl: "https://zuse.lk/solutions/it-infrastructure/",
    },
    "/solutions/it-development": {
      title: "IT Development Solution Details",
      description:
        "Innovative software made simple. Agile, automated & cutting-edge development. Expert team, seamless UX. Request a quote!",
      keywords:
        "IT development services, Software development, Custom software solutions, Web development services, Mobile app development",
      canonicalUrl: "https://zuse.lk/solutions/it-development/",
    },
    "/solutions/it-development/": {
      title: "IT Development Solution Details",
      description:
        "Innovative software made simple. Agile, automated & cutting-edge development. Expert team, seamless UX. Request a quote!",
      keywords:
        "IT development services, Software development, Custom software solutions, Web development services, Mobile app development",
      canonicalUrl: "https://zuse.lk/solutions/it-development/",
    },
    "/solutions/education": {
      title: "Education Solution Details",
      description:
        "Tech talent, unite! Join Zuse & build revolutionary software with cutting-edge solutions.",
      keywords:
        "Learning Management System, LMS software, Custom LMS solutions, Educational LMS, LMS implementation",
      canonicalUrl: "https://zuse.lk/solutions/education/",
    },
    "/solutions/education/": {
      title: "Education Solution Details",
      description:
        "Tech talent, unite! Join Zuse & build revolutionary software with cutting-edge solutions.",
      keywords:
        "Learning Management System, LMS software, Custom LMS solutions, Educational LMS, LMS implementation",
      canonicalUrl: "https://zuse.lk/solutions/education/",
    },
    "/portfolio/lyceum-international-schools": {
      title: "Lyceum International Schools Project Details",
      description:
        "Zuse Technologies' remarkable web solution for Lyceum International School clinched us the gold award at Bestweb 2023.",
      keywords:
        "Lyceum International Schools Project, Educational IT solutions, School management systems, Learning management systems (LMS), Digital learning platforms",
      canonicalUrl: "https://zuse.lk/portfolio/lyceum-international-schools/",
    },
    "/portfolio/lyceum-international-schools/": {
      title: "Lyceum International Schools Project Details",
      description:
        "Zuse Technologies' remarkable web solution for Lyceum International School clinched us the gold award at Bestweb 2023.",
      keywords:
        "Lyceum International Schools Project, Educational IT solutions, School management systems, Learning management systems (LMS), Digital learning platforms",
      canonicalUrl: "https://zuse.lk/portfolio/lyceum-international-schools/",
    },
    "/portfolio/book-studio": {
      title: "Book Studio Project Details",
      description:
        "Our elegant solutions and user-friendly interface make us the ultimate choice for anyone looking to buy books online.",
      keywords:
        "Book Studio Project, Book Studio IT solutions, Publishing IT solutions, Digital book studio, Book production technology",
      canonicalUrl: "https://zuse.lk/portfolio/book-studio/",
    },
    "/portfolio/book-studio/": {
      title: "Book Studio Project Details",
      description:
        "Our elegant solutions and user-friendly interface make us the ultimate choice for anyone looking to buy books online.",
      keywords:
        "Book Studio Project, Book Studio IT solutions, Publishing IT solutions, Digital book studio, Book production technology",
      canonicalUrl: "https://zuse.lk/portfolio/book-studio/",
    },
    "/portfolio/lyceum-campus": {
      title: "Lyceum Campus Project Details",
      description:
        "A modern, user-centric higher education website that offers a wide range of courses and programs using the latest technology.",
      keywords:
        "Lyceum Campus Project, Lyceum Campus IT solutions, Educational IT solutions, Campus digital transformation, Lyceum Campus IT infrastructure",
      canonicalUrl: "https://zuse.lk/portfolio/lyceum-campus/",
    },
    "/portfolio/lyceum-campus/": {
      title: "Lyceum Campus Project Details",
      description:
        "A modern, user-centric higher education website that offers a wide range of courses and programs using the latest technology.",
      keywords:
        "Lyceum Campus Project, Lyceum Campus IT solutions, Educational IT solutions, Campus digital transformation, Lyceum Campus IT infrastructure",
      canonicalUrl: "https://zuse.lk/portfolio/lyceum-campus/",
    },
    "/portfolio/ncg-warehouse": {
      title: "NCG Warehouse Project Details",
      description:
        "NCGWarehouse is a comprehensive and reliable resource for the modern warehousing industry.",
      keywords:
        "NCG Warehouse Project, Warehouse management systems, Inventory management solutions, Logistics IT solutions, Warehouse automation",
      canonicalUrl: "https://zuse.lk/portfolio/ncg-warehouse/",
    },
    "/portfolio/ncg-warehouse/": {
      title: "NCG Warehouse Project Details",
      description:
        "NCGWarehouse is a comprehensive and reliable resource for the modern warehousing industry.",
      keywords:
        "NCG Warehouse Project, Warehouse management systems, Inventory management solutions, Logistics IT solutions, Warehouse automation",
      canonicalUrl: "https://zuse.lk/portfolio/ncg-warehouse/",
    },
    "/portfolio/lyceum-assessment": {
      title: "Lyceum Assessment Project Details",
      description: "A modern, user-centric platform for online examinations.",
      keywords:
        "Lyceum Assessment Project, Assessment IT solutions, Educational assessment tools, Digital assessment solutions, Student assessment systems",
      canonicalUrl: "https://zuse.lk/portfolio/lyceum-assessment/",
    },
    "/portfolio/lyceum-assessment/": {
      title: "Lyceum Assessment Project Details",
      description: "A modern, user-centric platform for online examinations.",
      keywords:
        "Lyceum Assessment Project, Assessment IT solutions, Educational assessment tools, Digital assessment solutions, Student assessment systems",
      canonicalUrl: "https://zuse.lk/portfolio/lyceum-assessment/",
    },
    "/portfolio/nextgen-publications": {
      title: "Nextgen Publications Project Details",
      description:
        "Modern, user-centric platform for school publications with trial soft copy, built on React/Laravel stack.",
      keywords:
        "NextGen Publications Project, Digital publishing solutions, Publication management systems, Content management systems (CMS), Digital content solutions",
      canonicalUrl: "https://zuse.lk/portfolio/nextgen-publications/",
    },
    "/portfolio/nextgen-publications/": {
      title: "Nextgen Publications Project Details",
      description:
        "Modern, user-centric platform for school publications with trial soft copy, built on React/Laravel stack.",
      keywords:
        "NextGen Publications Project, Digital publishing solutions, Publication management systems, Content management systems (CMS), Digital content solutions",
      canonicalUrl: "https://zuse.lk/portfolio/nextgen-publications/",
    },
    "/portfolio/gatekeeper": {
      title: "Gatekeeper Project Details",
      description:
        "Achieve higher productivity, cost savings, and contribute to environmental sustainability with Zuse Gatekeeper.",
      keywords:
        "Gatekeeper Project, Access control solutions, Security management systems, Visitor management systems, Gate access control",
      canonicalUrl: "https://zuse.lk/portfolio/gatekeeper/",
    },
    "/portfolio/gatekeeper/": {
      title: "Gatekeeper Project Details",
      description:
        "Achieve higher productivity, cost savings, and contribute to environmental sustainability with Zuse Gatekeeper.",
      keywords:
        "Gatekeeper Project, Access control solutions, Security management systems, Visitor management systems, Gate access control",
      canonicalUrl: "https://zuse.lk/portfolio/gatekeeper/",
    },
    "/products/magiya": {
      title: "Magiya",
      description:
        "Magiya is a premium ticket booking experience offered by NCG Travels in association with technology partner Zuse Technologies.",
      keywords:
        "Magiya IT solutions, Magiya software development, Magiya technology services, Magiya digital transformation, Magiya IT consulting",
      canonicalUrl: "https://zuse.lk/products/magiya/",
    },
    "/products/magiya/": {
      title: "Magiya",
      description:
        "Magiya is a premium ticket booking experience offered by NCG Travels in association with technology partner Zuse Technologies.",
      keywords:
        "Magiya IT solutions, Magiya software development, Magiya technology services, Magiya digital transformation, Magiya IT consulting",
      canonicalUrl: "https://zuse.lk/products/magiya/",
    },
    "/products/bitrix24-gold-partner": {
      title: "Bitrix24 - Gold Partner",
      description:
        "An all-in-one free platform to manage your team and run your sales. Bitrix24 is designed to be seamlessly integrated into all your business processes.",
      keywords:
        "Bitrix24 solutions, Bitrix24 CRM, Bitrix24 customization, Bitrix24 consulting, Bitrix24 implementation",
      canonicalUrl: "https://zuse.lk/products/bitrix24-gold-partner/",
    },
    "/products/bitrix24-gold-partner/": {
      title: "Bitrix24 - Gold Partner",
      description:
        "An all-in-one free platform to manage your team and run your sales. Bitrix24 is designed to be seamlessly integrated into all your business processes.",
      keywords:
        "Bitrix24 solutions, Bitrix24 CRM, Bitrix24 customization, Bitrix24 consulting, Bitrix24 implementation",
      canonicalUrl: "https://zuse.lk/products/bitrix24-gold-partner/",
    },
    "/category/web-solutions": {
      title: "Web Solutions Category",
      description:
        "Boost your online presence with innovative web solutions. Expert insights on design, development, SEO, and more from Zuse Technologies. Read now!",
      keywords:
        "Web solutions, Website development, Web design services, Custom web development, E-commerce solutions",
      canonicalUrl: "https://zuse.lk/category/web-solutions/",
    },
    "/category/web-solutions/": {
      title: "Web Solutions Category",
      description:
        "Boost your online presence with innovative web solutions. Expert insights on design, development, SEO, and more from Zuse Technologies. Read now!",
      keywords:
        "Web solutions, Website development, Web design services, Custom web development, E-commerce solutions",
      canonicalUrl: "https://zuse.lk/category/web-solutions/",
    },
    "/category/standalone": {
      title: "Standalone Category",
      description:
        "Standalone software solutions for your unique needs. Explore project management, CRM, and custom options from Zuse Technologies. Learn more!",
      keywords:
        "Standalone software solutions, Standalone applications, Standalone IT services, Custom standalone solutions, Standalone software development",
      canonicalUrl: "https://zuse.lk/category/standalone/",
    },
    "/category/standalone/": {
      title: "Standalone Category",
      description:
        "Standalone software solutions for your unique needs. Explore project management, CRM, and custom options from Zuse Technologies. Learn more!",
      keywords:
        "Standalone software solutions, Standalone applications, Standalone IT services, Custom standalone solutions, Standalone software development",
      canonicalUrl: "https://zuse.lk/category/standalone/",
    },
    "/blog": {
      title: "Blog",
      description:
        "Explore the latest insights, trends, and updates in the IT industry. Stay informed with our expert articles and resources.",
      keywords:
        "zuse blog, IT industry insights, IT trends, technology updates, IT resources",
      canonicalUrl: "https://zuse.lk/blog/",
    },
    "/blog/": {
      title: "Blog",
      description:
        "Explore the latest insights, trends, and updates in the IT industry. Stay informed with our expert articles and resources.",
      keywords:
        "zuse blog, IT industry insights, IT trends, technology updates, IT resources",
      canonicalUrl: "https://zuse.lk/blog/",
    },
    "/blog-category/general-it-services": {
      title: "General IT Services",
      description:
        "Browse our articles on general IT services. Learn about the latest technologies, best practices, and expert tips to enhance your IT strategy.",
      keywords:
        "General IT services, IT service articles, IT best practices, IT strategy, technology tips",
      canonicalUrl: "https://zuse.lk/blog-category/general-it-services/",
    },
    "/blog-category/general-it-services/": {
      title: "General IT Services",
      description:
        "Browse our articles on general IT services. Learn about the latest technologies, best practices, and expert tips to enhance your IT strategy.",
      keywords:
        "General IT services, IT service articles, IT best practices, IT strategy, technology tips",
      canonicalUrl: "https://zuse.lk/blog-category/general-it-services/",
    },
    "/blog-author/sandakalum-senevirathna": {
      title: "Articles by Sandakalum Senevirathna",
      description:
        "Discover articles written by Sandakalum Senevirathna. Gain insights and expertise from an industry leader in IT services and technology.",
      keywords:
        "Sandakalum Senevirathna, Tech Lead Zuse Technologies, IT expert articles, technology insights, IT services",
      canonicalUrl: "https://zuse.lk/blog-author/sandakalum-senevirathna/",
    },
    "/blog-author/sandakalum-senevirathna/": {
      title: "Articles by Sandakalum Senevirathna",
      description:
        "Discover articles written by Sandakalum Senevirathna. Gain insights and expertise from an industry leader in IT services and technology.",
      keywords:
        "Sandakalum Senevirathna, Tech Lead Zuse Technologies, IT expert articles, technology insights, IT services",
      canonicalUrl: "https://zuse.lk/blog-author/sandakalum-senevirathna/",
    },
    "/blog-author/sandev-dullewa": {
        title: "Articles by Sandev Dullewa",
        description:
          "Explore articles by Sandev Dullewa, an expert in IT solutions for businesses in Sri Lanka. Gain insights and knowledge from a seasoned professional.",
        keywords:
          "Sandev Dullewa, Software Engineer Zuse Technologies, IT solutions expert, Sri Lanka IT services, business technology insights",
        canonicalUrl: "https://zuse.lk/blog-author/sandev-dullewa/",
      },
      "/blog-author/sandev-dullewa/": {
        title: "Articles by Sandev Dullewa",
        description:
          "Explore articles by Sandev Dullewa, an expert in IT solutions for businesses in Sri Lanka. Gain insights and knowledge from a seasoned professional.",
        keywords:
          "Sandev Dullewa, Software Engineer Zuse Technologies, IT solutions expert, Sri Lanka IT services, business technology insights",
        canonicalUrl: "https://zuse.lk/blog-author/sandev-dullewa/",
      },
    "/blog-details/leading-it-service-trends-to-watch-in-2024": {
      title: "Leading IT Service Trends to Watch in 2024",
      description:
        "Stay ahead in the evolving IT landscape by exploring the top trends that will shape IT services in 2024. Discover the future of AI, cloud computing, edge solutions, and more.",
      keywords:
        "IT service trends 2024, AI in IT services, cloud-native applications, edge computing, cybersecurity",
      canonicalUrl:
        "https://zuse.lk/blog-details/leading-it-service-trends-to-watch-in-2024/",
    },
    "/blog-details/leading-it-service-trends-to-watch-in-2024/": {
      title: "Leading IT Service Trends to Watch in 2024",
      description:
        "Stay ahead in the evolving IT landscape by exploring the top trends that will shape IT services in 2024. Discover the future of AI, cloud computing, edge solutions, and more.",
      keywords:
        "IT service trends 2024, AI in IT services, cloud-native applications, edge computing, cybersecurity",
      canonicalUrl:
        "https://zuse.lk/blog-details/leading-it-service-trends-to-watch-in-2024/",
    },
    "/blog-details/ultimate-guide-to-choosing-it-solutions-in-sri-lanka": {
      title: "Ultimate Guide to Choosing IT Solutions in Sri Lanka",
      description:
        "Navigate the complex world of IT solutions with our ultimate guide tailored for businesses in Sri Lanka. Learn about ERP, CRM, cloud computing, and more to make the best tech choices.",
      keywords:
        "IT solutions Sri Lanka, choosing IT solutions, ERP, CRM, cloud computing, cybersecurity, business intelligence tools",
      canonicalUrl:
        "https://zuse.lk/blog-details/ultimate-guide-to-choosing-it-solutions-in-sri-lanka/",
    },
    "/blog-details/ultimate-guide-to-choosing-it-solutions-in-sri-lanka/": {
      title: "Ultimate Guide to Choosing IT Solutions in Sri Lanka",
      description:
        "Navigate the complex world of IT solutions with our ultimate guide tailored for businesses in Sri Lanka. Learn about ERP, CRM, cloud computing, and more to make the best tech choices.",
      keywords:
        "IT solutions Sri Lanka, choosing IT solutions, ERP, CRM, cloud computing, cybersecurity, business intelligence tools",
      canonicalUrl:
        "https://zuse.lk/blog-details/ultimate-guide-to-choosing-it-solutions-in-sri-lanka/",
    }
  };

  const { pathname } = location;
  const { title, description, keywords, canonicalUrl } =
    metaTags[pathname] || {};

  const breadcrumbList = [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://zuse.lk/",
    },
  ];

  if (pathname !== "/") {
    const pathParts = pathname.split("/").filter(Boolean);
    pathParts.forEach((part, index) => {
      const url = `https://zuse.lk/${pathParts.slice(0, index + 1).join("/")}/`;
      breadcrumbList.push({
        "@type": "ListItem",
        position: index + 2,
        name: part.replace(/-/g, " "),
        item: url,
      });
    });
  }

  return (
    <Helmet>
      <title>{title ? `${title} | ${siteName}` : defaultTitle}</title>
      <meta name="robots" content="index, follow" />
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <link rel="canonical" href={canonicalUrl || defaultCanonicalUrl} />

      {/* Breadcrumb Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: breadcrumbList,
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
