import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPhone,
  FaFax,
  FaTiktok,
  FaInstagram,
} from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  const openLinkInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li
                onClick={() => openLinkInNewTab(process.env.PUBLIC_URL + "/")}
              >
                Home
              </li>
              <li
                onClick={() =>
                  openLinkInNewTab(process.env.PUBLIC_URL + "/about-us")
                }
              >
                About Us
              </li>
              <li
                onClick={() =>
                  openLinkInNewTab(process.env.PUBLIC_URL + "/team")
                }
              >
                Team
              </li>
              <li onClick={() => openLinkInNewTab("https://careers.zuse.lk/")}>
                Careers
              </li>
              <li
                onClick={() =>
                  openLinkInNewTab(process.env.PUBLIC_URL + "/contact")
                }
              >
                Contact Us
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Contact Information</span>
                  <p>
                    9th Floor,
                    <br />
                    10, Raymond Rd, Nugegoda,
                    <br />
                    10250, Sri Lanka
                  </p>
                </address>
                <address className="address">
                  <span className="title">
                    We're Available 9.00am - 6.00pm. Call Now.
                  </span>
                  <a href="tel:+94112145700 " className="tel">
                    <FaPhone /> +94 011 214 5700 (Office){" "}
                  </a>
                  <a href="tel:+94761368132" className="tel">
                    <FaFax /> +94 76 136 8132 (Hotline){" "}
                  </a>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <FaFacebookF
                        onClick={() =>
                          openLinkInNewTab(
                            "https://web.facebook.com/zusetechnologies"
                          )
                        }
                      />
                    </li>
                    <li>
                      <FaInstagram
                        onClick={() =>
                          openLinkInNewTab(
                            "https://www.instagram.com/zusetech__/"
                          )
                        }
                      />
                    </li>
                    <li>
                      <FaLinkedinIn
                        onClick={() =>
                          openLinkInNewTab(
                            "https://www.linkedin.com/company/zusetechnologies/"
                          )
                        }
                      />
                    </li>
                    <li>
                      <FaTiktok
                        onClick={() =>
                          openLinkInNewTab("https://www.tiktok.com/@zusetech")
                        }
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
